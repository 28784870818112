.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-title {
  font-size: 4em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

canvas {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 800px;
}

/**
Should check here for more fonts:
https://fonts.google.com/
possible guide here:
https://blog.logrocket.com/add-custom-fonts-mui/
*/


@font-face {
  font-family: 'Astonpoliz';
  src: url('https://www.toadandfungi.com/Astonpoliz.ttf');
}

@font-face {
  font-family: 'Beinancia';
  src: url('https://www.toadandfungi.com/Beinancia-Regular.ttf');
}

@font-face {
  font-family: 'BulletinGothic';
  src: url('https://www.toadandfungi.com/BulletinGothic.otf');
}

@font-face {
  font-family: 'CaracasFina';
  src: url('https://www.toadandfungi.com/CaracasFina2.0.ttf');
}

@font-face {
  font-family: 'DamesplayScript';
  src: url('https://www.toadandfungi.com/DamesplayScript.ttf');
}

@font-face {
  font-family: 'HandStylus';
  src: url('https://www.toadandfungi.com/HandStylus-Regular.ttf');
}

@font-face {
  font-family: 'HoaxVandal';
  src: url('https://www.toadandfungi.com/HoaxVandal.ttf');
}

@font-face {
  font-family: 'OpenSauceSans';
  src: url('https://www.toadandfungi.com/OpenSauceSans-Medium.ttf');
}

@font-face {
  font-family: 'PixelArmy';
  src: url('https://www.toadandfungi.com/pixelarmy.medium.ttf');
}

@font-face {
  font-family: 'RocksSerif';
  src: url('https://www.toadandfungi.com/gomarice_rocks_serif.ttf');
}

@font-face {
  font-family: 'SantaChild';
  src: url('https://www.toadandfungi.com/SantaChild.otf');
}

@font-face {
  font-family: 'VintellaSkillton';
  src: url('https://www.toadandfungi.com/VintellaSkillton.otf');
}